import React, { useState } from 'react';
import InputDatePicker from '../components/inputDatePicker';
import { InputText } from '../components/inputText';
import InputSelect from '../components/inputSelect';
import { useProfessionalsActive } from '../hooks/useProfessionalsActive';

export default function CreateFeriado ({ onSubmit }) {
    const { professionals } = useProfessionalsActive();
    const [formData, setFormData] = useState({
        titulo: '',
        fecha: '',
        profesional: ''
    });

    const handleTextChange = async (e) => {
        const { name, value } = e.target;
        const newFormData = {
            ...formData,
            [name]: value
        };
        setFormData(newFormData);
        onSubmit(newFormData);
    };

    const handleDateChange = (date, fieldName) => {
        const newFormData = {
            ...formData,
            [fieldName]: date
        };
        setFormData(newFormData);
        onSubmit(newFormData);
    };

    const handleProfessionalChange = (event, selectedOption) => {
        const profesional = selectedOption[0]?.codigo || '';
        const newFormData = {
            ...formData,
            profesional
        };
        setFormData(newFormData);
        onSubmit(newFormData);
    };

    return (
        <form className="py-12 flex flex-col gap-10" id="createFeriado">
            <div className="col flex flex-col">
                <InputText
                    ide="titulo"
                    label="Motivo"
                    name="titulo"
                    value={formData.titulo || ''}
                    onChange={handleTextChange}
                />
            </div>
            <div className="col flex flex-col">
                <InputDatePicker
                    ide="fecha"
                    label="Fecha"
                    disabledPast={true}
                    value={formData.fecha || ''}
                    onChange={(value) => handleDateChange(value, 'fecha')}
                />
            </div>
            <div className="col flex flex-col">
                <InputSelect
                    ide="profesional"
                    pars={{ darkMode: false, ide: 'profesional', label: 'Profesional' }}
                    data={professionals}
                    onChange={handleProfessionalChange}
                />
                <p className="text-sm">* Déjelo vacío para asignárselo a todos</p>
            </div>
        </form>
    );
}
