import React, { useState, useEffect, useMemo } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Header } from './parts/Header';
import LoginForm from './forms/login';
import { AddProfesional } from './forms/AddProfesional';
import { BusquedaPaciente } from './forms/Busqueda_paciente';
import { AddPaciente } from './forms/AddPaciente';
import { Turns } from './forms/Turns';
import { loginService } from './services/data.service';
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material';
import { CreateHorarios } from './forms/CreateHorarios';
import HistorialClinico from './forms/HistorialClinico';
import ObraSocial from './forms/ObraSocial';
import Users from './forms/Users';
import { NewVisit } from './forms/NewVisit';
import { PacientesPresentes } from './forms/PacientesPresentes';
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

function App () {
    const sesion = sessionStorage.getItem('session') === 'true';
    const [darkMode, setDarkMode] = useState(() => {
        return localStorage.getItem('darkMode') === 'true';
    });
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [error, setError] = useState(false);
    const [personaSeleccionada, setPersonaSeleccionada] = useState(null);
    const [cookies, setCookie] = useCookies(['profCod']);
    const [isProfessional, setIsProfessional] = useState(cookies.profCod !== undefined);

    useEffect(() => {
        const esPrimeraVisitaSemana = localStorage.getItem('primeraVisitaSemana') === 'true';
        const diaActualSemana = new Date().getDay();

        if (diaActualSemana === 1 && !esPrimeraVisitaSemana) {
            handleLogOut();
        }
    }, []);

    useEffect(() => {
        const isProf = cookies.profCod;
        if (isProf) {
            setIsProfessional(isProf !== undefined);
        }
    }, [cookies]);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light'
                }
            }),
        [darkMode]
    );

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        localStorage.setItem('darkMode', !darkMode);
    };

    const handleLogin = async (data) => {
        try {
            const loginData = data;
            delete loginData.sesion;
            const response = await loginService(loginData);
            sessionStorage.setItem('user', JSON.stringify({
                token: response.data.token,
                userName: data.email,
                profCodToken: response.data.user.prof_cod
            }));
            const encryptedToken = CryptoJS.AES.encrypt(response.data.token, 'YOUR_SECRET_KEY_CENTRO_RECOLETA').toString();
            setCookie('authToken', encryptedToken, { path: '/', secure: true, sameSite: 'Strict' });
            setCookie('userName', data.email, { path: '/' });
            setCookie('profCod', response.data.user.prof_cod, { path: '/' });
            setIsProfessional(response.data.user.prof_cod !== null);
            sessionStorage.setItem('nameProf', JSON.stringify({
                name: response.data.user.name
            }));
            sessionStorage.setItem('session', 'true');
            setLoggedIn(response.data.status);
            setError(!response.data.status);
            return true;
        } catch (error) {
            console.error(error);
            setError(true);
            return false;
        }
    };

    const navigate = useNavigate();
    const handleLogOut = () => {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('primeraVisitaSemana', 'true');
        setLoggedIn(false);
        navigate('/');
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={'min-h-screen ' + (darkMode ? 'bg-gradient-to-b from-zinc-600 via-zinc-700 to-zinc-900' : 'bg-gradient-to-b from-slate-100 via-slate-200 to-slate-400')}>
                {(isLoggedIn || sesion) && <Header darkMode={darkMode} handleLogOut={handleLogOut} toggleDarkMode={toggleDarkMode} />}
                <Routes>
                    <Route
                        path="/"
                        element={
                            (isLoggedIn || sesion)
                                ? (
                                    isProfessional
                                        ? <Navigate to="/pacientesPresentes" />
                                        : <Navigate to="/turnos" />
                                )
                                : (
                                    <LoginForm onLogin={handleLogin} error={error} />
                                )
                        }
                    />
                    <Route path="/busqueda" element={<BusquedaPaciente onSeleccionarPersona={setPersonaSeleccionada} />} />
                    <Route path="/nuevo" element={<AddPaciente userResult={personaSeleccionada} darkMode={darkMode} setPersonaSeleccionada={setPersonaSeleccionada}/>} />
                    <Route path="/nuevoProfesional" element={ <AddProfesional darkMode={darkMode}/>} />
                    <Route path="/turnos" element={ <Turns darkMode={darkMode} onSeleccionarPersona={setPersonaSeleccionada}/>} />
                    <Route path="/createHorario" element={ <CreateHorarios darkMode={darkMode}/>} />
                    <Route path='/historico' element={ <HistorialClinico darkMode={darkMode}/>} />
                    <Route path="/createUser" element={ <Users darkMode={darkMode}/>} />
                    <Route path="/obraSocial" element={ <ObraSocial darkMode={darkMode}/>} />
                    <Route path="/nuevaVisita" element={ <NewVisit darkMode={darkMode}/>} />
                    <Route path="/pacientesPresentes" element={ <PacientesPresentes darkMode={darkMode}/>} />
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
