import React, { useEffect, useState } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider, esES } from '@mui/x-date-pickers';
import es from 'date-fns/locale/es';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InputSelect from '../components/inputSelect.jsx';
import { TextArea } from '../components/inputTextArea';
import Boton from '../components/boton.jsx';
import { errorPopup, warningPopup } from '../components/popup.jsx';
import { AutoComplete } from '../components/autocomplete.jsx';
import EditTable from '../parts/EditTable.jsx';
import { emptyTurn } from '../constants/constants';
import { tableColumns, getSchedules, getTurns, getTurnsProfDate, formattedPaciente } from '../logic/turnos.js';
import { transformDate, checkNameDNI, transformDateToDay } from '../logic/utils';
import { saveTurn, deleteTurn, getSearchPacientesService, getPacienteById, saveIndividualTurn, getDispTurns, putTurn } from '../services/data.service.js';
import { ProfessionalCheckboxes } from '../parts/professionalCheckboxes.jsx';
import { useNavigate } from 'react-router-dom';
import CustomTurns from '../components/CustomTurns';
import { Loading } from '../components/loading';
import { useObraSociales } from '../hooks/useObraSociales';
import { CircularProgress } from '@mui/material';

export function Turns (pars) {
    /** ************** Variables ****************/
    const [professional, setProfessional] = useState([{ codigo: -1, text: '' }]);
    const [patient, setPatient] = useState('');
    const [valueCreatePaciente, setValueCreatePaciente] = useState('');
    const [clearAC, setClearAC] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [dateTable, setDateTable] = useState([]);
    const [turn, setTurn] = useState(emptyTurn);
    const [loading, setLoading] = useState(false);
    const [loadingDate, setLoadingDate] = useState(false);
    const [loadingTotal, setLoadingTotal] = useState(false);
    const [disabledCreatePaciente, setDisabledCreatePaciente] = useState(true);
    const [openPopup, setOpenPopup] = useState(false);
    const [dispTurns, setDispTurns] = useState([]);
    const [noDispTurns, setNoDispTurns] = useState([]);
    const navigate = useNavigate();
    const { obrasociales } = useObraSociales();
    const selectedDateBeforeClear = turn.fecha;
    /** ************** Hooks ***********************/
    useEffect(() => {
        setTimeout(() => {
            const enable = professional !== -1 && turn.paciente_id !== '' && turn.horario_id !== '';
            setDisabledButton(!enable);
        }, 100);
        if (localStorage.getItem('selectedDate') !== null) {
            const date = new Date(localStorage.getItem('selectedDate'));
            setTurn({
                ...turn,
                fecha: date
            });
        }
    }, [turn, professional]);

    useEffect(() => {
        const localTurn = JSON.parse(localStorage.getItem('turn'));
        const localProfessional = JSON.parse(localStorage.getItem('professional'));
        if (localTurn !== null) {
            setTurn({
                ...localTurn,
                fecha: new Date(localTurn.fecha)
            });

            localStorage.removeItem('turn');
        }

        if (localStorage.getItem('newPaciente') !== null && localStorage.getItem('newPaciente') !== 'undefined') {
            const pacienteData = JSON.parse(localStorage.getItem('newPaciente'));
            setPatient(`${pacienteData.Nombre} - ${pacienteData.NumDocumento}`);
            setTurn((prevTurn) => ({
                ...prevTurn,
                paciente_id: pacienteData.id
            }));
        }

        if (localProfessional !== null) {
            setProfessional(localProfessional);
            localStorage.removeItem('professional');
        }

        if (localTurn !== null && localTurn.fecha !== null && localProfessional !== null) {
            handleSchedules(localTurn.fecha, localProfessional);
            handleTurnos(localTurn.fecha, localProfessional);
            handleTurnosXProf(localTurn.fecha, localProfessional);
            getDateWithTurns(localProfessional[0].codigo);
        }
        localStorage.clear();
    }, []);

    const handleSchedules = async (fecha, prof) => {
        const response = await getSchedules(fecha, prof);
        if (response) {
            setSchedule(response);
        }
    };

    const handleTurnos = async (fecha, professional) => {
        const response = await getTurns(fecha, professional);
        if (response) {
            setDateTable(response);
        }
    };

    const handleTurnosXProf = async (fecha, profCod) => {
        const response = await getTurnsProfDate(fecha, profCod);
        if (response) {
            setDateTable(response);
        }
    };

    // manejo de eventos
    const handlePicker = (date) => {
        setTurn({ ...turn, fecha: date });
        handleSchedules(date, professional); // used to get all schedules available
        handleTurnos(date, professional); // used to get the turns of the day
    };

    const handleChangeSelector = (event, opt) => {
        const { name } = event.target;
        if (name === 'horario_id') {
            setTurn({ ...turn, horario_id: opt[0].id });
        } else {
            setProfessional(opt);
            handleSchedules(turn.fecha, opt);
            handleTurnosXProf(turn.fecha, opt);
            handleTurnos(turn.fecha, opt);
            getDateWithTurns(opt[0].codigo);
        }
    };

    const getDateWithTurns = async (profCode) => {
        setLoadingDate(true);
        const response = await getDispTurns(profCode); // Contiene los turnos disponibles y no disponibles
        setLoadingDate(false);
        if (response) {
            setDispTurns(response.fechasDisponibles);
            setNoDispTurns(response.fechasNoDisponibles);
        }
    };

    const handleAutoComplete = (opc) => {
        if (opc.length > 0) {
            setTurn({
                ...turn,
                paciente_id: opc[0].id
            });
        } else {
            setPatient('');
        }
    };

    const handleObraSocial = (opc) => {
        if (opc.length > 0) {
            setTurn({
                ...turn,
                obra_social: opc[0].codigo,
                obra_social_text: opc[0].text
            });
        } else {
            setPatient('');
        }
    };

    const handleObservationChange = (event) => {
        setTurn({
            ...turn,
            observ: event.target.value
        });
    };

    const saveNewTurn = () => {
        warningPopup({
            title: 'Está a punto de asignar un nuevo turno.',
            text: '¿Está seguro de que quiere continuar?'
        })
            .then(async (response) => {
                if (response.isConfirmed) {
                    const fecha = turn.fecha;
                    turn.fecha = transformDate(turn.fecha);
                    const response = await saveTurn(turn);
                    if (response) {
                        clearForm();
                    } else {
                        setTurn({ ...turn, fecha: fecha });
                    }
                }
            });
    };

    const clearForm = () => {
        setClearAC(true);
        setPatient('');
        setSchedule([]);
        setDisabledButton(false);
        setTurn({ ...emptyTurn, fecha: selectedDateBeforeClear });
        setTimeout(() => {
            setClearAC(false);
        }, 1000);
        setTimeout(() => {
            setLoading(false);
            handleTurnosXProf(turn.fecha, professional);
            handleSchedules(turn.fecha, professional);
        }, 1000);
    };

    const handleDelete = async (id) => {
        const response = await warningPopup({
            title: 'Está a punto de eliminar un turno.',
            text: '¿Está seguro de que quiere continuar?'
        });
        if (response.isConfirmed) {
            const deleteResponse = await deleteTurn(id);
            if (deleteResponse) {
                await handleSchedules(turn.fecha, professional);
                await handleTurnos(turn.fecha, professional);
            } else {
                errorPopup({ title: 'Error', text: 'No se pudo eliminar el turno.' });
            }
        }
    };

    const handleDeletePaciente = async (data) => {
        warningPopup({
            title: 'Está a punto de eliminar el turno para el paciente.',
            text: '¿Está seguro de que quiere continuar?'
        })
            .then((response) => {
                const newData = {
                    horario_id: data.id,
                    obra_social: null,
                    paciente_id: null,
                    observ: null,
                    presente: null,
                    atendido: null,
                    primeraVisita: null
                };
                if (response.isConfirmed) {
                    setLoading(true);
                    const response = saveTurn(newData);
                    if (response) {
                        setTimeout(() => {
                            handleTurnosXProf(turn.fecha, professional);
                            handleSchedules(turn.fecha, professional);
                            setLoading(false);
                        }, 3000);
                    }
                }
            });
    };

    const handleModify = async (id) => {
        const paciente = dateTable.find((turno) => turno.id === id).modify_paciente;
        if (paciente) {
            setLoadingTotal(true);
            const pacienteNew = await getPacienteById(paciente.id);
            if (pacienteNew) {
                setLoadingTotal(false);
                localStorage.setItem('turn', JSON.stringify(turn));
                localStorage.setItem('professional', JSON.stringify(professional));
                localStorage.setItem('editPaciente', JSON.stringify(pacienteNew));
                navigate('/nuevo');
            } else {
                setLoadingTotal(false);
                console.error('Error al navegar a /nuevo:');
            }
        }
    };

    const handleHistorial = async (id) => {
        const turnoWithId = dateTable.find((turno) => turno.id === id);
        if (!turnoWithId.paciente_id || turnoWithId.paciente_id === '-') {
            errorPopup({ title: 'Error', text: 'Paciente no encontrado' });
            return;
        }
        let paciente = await getPacienteById(turnoWithId.paciente_id);
        paciente = formattedPaciente(paciente);
        localStorage.setItem('selectedDate', turn.fecha);
        if (professional[0].codigo !== -1) {
            localStorage.setItem('selectedProf', JSON.stringify(professional));
        }
        navigate('/historico', { state: { paciente, turnId: id } });
    };

    const handleFetch = async (value) => {
        const response = await getSearchPacientesService(value);
        if (!response.enabled) {
            setDisabledCreatePaciente(false);
            setValueCreatePaciente(value);
        }
        return response;
    };

    const handleOpenWarningPopup = () => {
        warningPopup({
            title: 'Paciente no encontrado',
            text: '¿Desea crear un nuevo paciente?',
            confirmButtonText: 'Crear'
        }).then((response) => {
            if (response.isConfirmed) {
                localStorage.setItem('turn', JSON.stringify(turn));
                const value = checkNameDNI(valueCreatePaciente);
                localStorage.setItem('professional', JSON.stringify(professional));
                navigate('/nuevo', { state: { NombreDoc: value, returnTo: '/turnos' } });
            }
        });
    };

    const saveOverTurn = (data) => {
        const newData = {
            ...data,
            paciente_id: turn.paciente_id,
            prof_cod: professional[0].codigo,
            obra_social: turn.obra_social,
            observ: turn.observ,
            fecha: transformDate(turn.fecha)
        };
        warningPopup({
            title: 'Está a punto de asignar un nuevo turno.',
            text: '¿Está seguro de que quiere continuar?'
        })
            .then(async (response) => {
                if (response.isConfirmed) {
                    saveIndividualTurn(newData);
                    const saveResponse = await saveIndividualTurn(newData);
                    if (saveResponse && saveResponse.status >= 200 && saveResponse.status < 300) {
                        clearForm();
                    }
                }
            });
    };

    const handleOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleEditCell = (row) => {
        const { id, observaciones } = row;
        if (observaciones === null) { // No llegan correctamente las observaciones si es null
            errorPopup({ title: 'Error', text: 'No se pudo guardar las observaciones' });
        }
        if (!id) { // Esta validación es en caso de que se quiera realizar un edit de observaciones de un turno no existente.
            errorPopup({ title: 'Error', text: 'No se encuentra el turno.' });
        }

        putTurn(id, { observ: observaciones }, true); // Si está todo OK, se realiza la llamada al update con el turno y las observaciones
    };

    return (
        <section className="p-16">
            <h2 className="text-3xl flex justify-center my-10 text-principal font-bold">
                <p>Asignar turnos</p>
            </h2>
            <div className="grid grid-cols-2 gap-5">
                <div className="flex flex-col justify-between">
                    <section>
                        { !loadingDate
                            ? (
                                <LocalizationProvider
                                    localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={es}
                                >
                                    <DateCalendar
                                        sx={{
                                            width: '100%',
                                            '& .MuiDayCalendar-header': {
                                                display: 'flex !important',
                                                justifyContent: 'space-around !important',
                                                width: '100%'
                                            },
                                            '& .MuiDayCalendar-weekContainer': {
                                                display: 'flex !important',
                                                justifyContent: 'space-around !important',
                                                width: '100%'
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: '#beac35 !important'
                                            }
                                        }}
                                        onChange={handlePicker} value={turn.fecha}
                                        slotProps={{
                                            day: (day) => {
                                                const disp = dispTurns.includes(transformDateToDay(day.day));
                                                const noDisp = noDispTurns.includes(transformDateToDay(day.day));
                                                return ({
                                                    sx: {
                                                        backgroundColor: disp ? '#28a745' : noDisp ? '#dc3545' : 'transparent',
                                                        color: ((disp || noDisp) && 'white'),
                                                        '&:hover': {
                                                            backgroundColor: disp ? '#28a745' : noDisp ? '#dc3545' : 'transparent'
                                                        }
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            )
                            : (
                                <div className='grid place-content-center'>
                                    <CircularProgress size={50} color="inherit" />
                                </div>
                            )
                        }
                        {
                            (dispTurns.length > 0 || noDispTurns.length > 0) && !loadingDate && (
                                <div>
                                    <section className='ms-8 flex gap-2 items-center mt-5'>
                                        <p id='color1' disabled className='w-3 h-3 rounded-full border-none bg-[#28a745]' />
                                        <p>Disponible</p>
                                    </section>
                                    <section className='ms-8 flex gap-2 items-center mt-2'>
                                        <p id='color1' disabled className='w-3 h-3 rounded-full border-none bg-[#dc3545]' />
                                        <p>No disponible</p>
                                    </section>
                                </div>
                            )
                        }
                    </section>
                    {dateTable.length > 0 &&
                    <div className='legend grid gap-2 ms-8'>
                        <label className='flex items-center gap-1' htmlFor='color1' >
                            <input id='color1' type="color" value="#beac35" disabled className='w-6 h-6 rounded' />
                            Presente
                        </label>
                        <label className='flex items-center gap-1' htmlFor='color2'>
                            <input id='color2' type="color" value="#00aab0" disabled className='w-6 h-6 rounded' />
                            Atendido
                        </label>
                    </div>
                    }
                </div>
                <aside className="col flex flex-col gap-5">
                    <ProfessionalCheckboxes darkMode={pars.darkMode} onChange={handleChangeSelector} initialValue={professional && professional[0].codigo}/>
                    <section className='grid grid-cols-12 gap-3 items-end'>
                        <div className='col-span-11'>
                            <AutoComplete
                                initialValue={patient}
                                clear={clearAC}
                                ide="paciente_id"
                                darkMode={pars.darkMode}
                                label="Seleccione un paciente"
                                onChange={handleAutoComplete}
                                dinamic={true}
                                data={[]}
                                onFetch={handleFetch}
                            />
                        </div>
                        <Boton estilo="h-fit w-fit" onClick={handleOpenWarningPopup} texto="+" disabled={disabledCreatePaciente}></Boton>
                    </section>
                    <AutoComplete initialValue={turn.obra_social_text} clear={clearAC} onChange={handleObraSocial} data={obrasociales} label='Obra social' darkMode={pars.darkMode} ide="obra_social" />
                    { (turn.fecha !== '') &&
                        <InputSelect pars={{ ide: 'horario_id', darkMode: pars.darkMode, label: 'Horarios disponibles' }} data={schedule.length > 0 ? schedule : setSchedule([{ id: -1, text: 'No hay turnos disponibles', disabled: true }])} onChange={handleChangeSelector}/>
                    }
                    <TextArea label="Observaciones" ide="observ" darkMode={pars.darkMode} inputValue={turn.observ} onChange={handleObservationChange} />
                    <div className="flex gap-2 justify-end mt-8 me-3">
                        <Boton estilo="w-fit" color={'bg-secondary hover:bg-secondary/60 active:bg-secondary/90'} darkMode={pars.darkMode} ide="saveOverTurn" texto="Guardar SobreTurno" onClick={handleOpenPopup}></Boton>
                        <Boton estilo="w-fit" darkMode={pars.darkMode} ide="saveTurn" texto="Guardar" onClick={saveNewTurn} disabled={disabledButton}></Boton>
                        <CustomTurns
                            open={openPopup}
                            onClose={handleClosePopup}
                            onSave={saveOverTurn}
                            fecha={turn.fecha}
                            professional={professional}
                            paciente={patient}
                        />
                    </div>
                </aside>
            </div>
            <section className='m-8'>
                {dateTable.length > 0 && <EditTable darkMode={pars.darkMode} onEditCell={handleEditCell} cells={tableColumns} dataRows={dateTable} actionsColumn handleDelete={handleDelete} handleDeletePaciente={handleDeletePaciente} handleModify={handleModify} navigateHistorial={handleHistorial} loadingEnable={loading}/>}
            </section>
            <Loading open={loadingTotal} />
        </section>

    );
}
