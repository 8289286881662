import axios from 'axios';
import { CONFIG } from '../constants/const_services';
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';

const cookies = new Cookies();
CONFIG.headers.Authorization = sessionStorage.getItem('user') !== null ? 'Bearer ' + (JSON.parse(sessionStorage.getItem('user'))).token : '';

export function get (url, needConfig = true) {
    return needConfig ? axios.get(url, CONFIG).catch(error => checkAuth(error)) : axios.get(url);
}

export function post (url, data, needConfig = true) {
    if (needConfig) {
        data.usuario = (JSON.parse(sessionStorage.getItem('user'))).userName;
    }
    return needConfig ? axios.post(url, data, CONFIG) : axios.post(url, data);
}

export function put (url, data, needConfig = true, multiple = false) {
    const userName = cookies.get('userName');
    if (!CONFIG.headers.Authorization) {
        setAuthHeader(getAuthToken());
    }
    if (needConfig && !multiple) {
        data.usuario = userName;
    }
    if (multiple) {
        data = data.map((d) => ({ ...d, usuario: userName }));
    }
    return needConfig ? axios.put(url, data, CONFIG) : axios.put(url, data);
}

export function deleteService (url, data, needConfig = true) {
    return needConfig ? axios.delete(url, CONFIG) : axios.delete(url);
}

export function setAuthHeader (token) {
    CONFIG.headers.Authorization = 'Bearer ' + token;
}

function checkAuth (err) {
    if (err.response.status === 401 && err.response.data.message === 'Unauthenticated.') {
        localStorage.clear();
        window.location.href = '/';
    }
    throw err;
}

const getAuthToken = () => {
    const encryptedToken = cookies.get('authToken');
    if (!encryptedToken) return null;

    try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, 'YOUR_SECRET_KEY_CENTRO_RECOLETA');
        const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedToken;
    } catch (error) {
        console.error('Error al desencriptar el token:', error);
        return null;
    }
};
