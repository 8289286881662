/* eslint-disable indent */
import { Box, LinearProgress, Checkbox, Menu, MenuItem, IconButton } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function EditTable ({ cells, dataRows, loadingEnable, onChange, actionsColumn = false, handleDelete, handleDeletePaciente, navigateHistorial, handleModify, darkMode, onEditCell }) {
    const [rows, setRows] = useState(dataRows || []);
    const [showLoading, setShowLoading] = useState(loadingEnable !== undefined ? loadingEnable : false);
    const [anchorEl, setAnchorEl] = useState(null); // Para el menú desplegable
    const [selectedRowId, setSelectedRowId] = useState(null); // Para realizar acciones en la fila seleccionada

    useEffect(() => {
        if (dataRows) {
            setRows(dataRows);
        }
    }, [dataRows]);

    useEffect(() => {
        setShowLoading(loadingEnable);
    }, [loadingEnable]);

    const handleCheckboxChange = (event, rowId, field, onChange) => {
        const updatedRows = rows.map(row => {
            if (row.id === rowId) {
                return { ...row, [field]: event.target.checked };
            }
            return row;
        });
        const changedRow = rows.find(row => row.id === rowId);
        setRows(updatedRows);
        if (onChange !== undefined) {
            onChange(event, event.target.name, changedRow);
        }
    };

    const handleActionClick = (event, rowId) => {
        setAnchorEl(event.currentTarget);
        setSelectedRowId(rowId);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleAction = (action) => {
        if (action === 'Eliminar horario' && handleDelete) {
            handleDelete(selectedRowId);
        }
        if (action === 'Cancelar turno/pte' && handleDeletePaciente) {
            const rowIndex = rows.findIndex(row => row.id === selectedRowId);
            handleDeletePaciente(rows[rowIndex]);
        }
        if (action === 'Historial' && navigateHistorial) {
            navigateHistorial(selectedRowId);
        }
        if (action === 'Modificar' && handleModify) {
            handleModify(selectedRowId);
        }
        handleCloseMenu();
    };

    const columns = cells.map((cell) => ({
        field: cell.field,
        headerName: cell.headerName,
        editable: cell.editable === undefined ? true : cell.editable,
        type: cell.options && cell.options.type ? cell.options.type : 'string',
        align: 'center',
        headerAlign: 'center',
        width: cell.width || undefined,
        flex: cell.flex || 1,
        name: cell.field,
        headerClassName: 'super-app-theme--header',
        renderCell: cell.options && cell.options.type === 'checkbox'
            ? (params) => {
                return (
                    <Checkbox
                        name={params.field}
                        disabled={cell.options.disabled}
                        checked={(params.row[params.field] === 1 || params.row[params.field] === true) || false}
                        onChange={(event) => handleCheckboxChange(event, params.row.id, params.field, cell.options.onChange)}
                        sx={{
                            color: darkMode ? '#fff' : '#359597',
                            '&.Mui-checked': {
                                color: darkMode ? '#fff' : '#359597',
                                '&.MuiButtonBase-root': {
                                    color: '#359597'
                                }
                            }
                        }}
                    />
                );
            }
            : null
    }));

    if (actionsColumn) {
        columns.push({
            field: 'actions',
            headerName: '',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerClassName: 'super-app-theme--header',
            width: 10,
            renderCell: (params) => (
                <>
                    <IconButton
                        aria-label="acciones"
                        aria-controls="acciones-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleActionClick(event, params.row.id)}
                    >
                        <MoreVertIcon sx={
                            { color: darkMode ? '#fff' : 'rgb(56, 56, 56)' }} />
                    </IconButton>
                    <Menu
                        id="acciones-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedRowId === params.row.id}
                        onClose={handleCloseMenu}
                    >
                        { handleDelete && <MenuItem onClick={() => handleAction('Eliminar horario')}>Eliminar horario</MenuItem>}
                        { handleDeletePaciente && <MenuItem onClick={() => handleAction('Cancelar turno/pte')}>Cancelar turno/pte</MenuItem>}
                        { navigateHistorial && <MenuItem onClick={() => handleAction('Historial')}>Ver historial</MenuItem>}
                        { handleModify && <MenuItem onClick={() => handleAction('Modificar')}>Modificar paciente</MenuItem>}
                    </Menu>
                </>
            )
        });
    }

    return (
        <Box
            sx={{
                '& .super-app-theme--header': {
                    backgroundColor: '#359597',
                    color: '#fff',
                    borderColor: '#359597'
                }
            }}
        >
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                editMode='row'
                hideFooter
                slots={{
                    loadingOverlay: LinearProgress
                }}
                loading={showLoading}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                onCellKeyDown={(params, event) => {
                    if (params.colDef.type === 'number') {
                        if (event.which !== 8 &&
                            event.which !== 0 &&
                            (event.which < 48 || event.which > 57) &&
                            (event.which < 96 || event.which > 105)
                        ) {
                            event.preventDefault();
                        }
                    }
                }}
                getRowClassName={(params) => {
                    let className = '';
                    if (params.row.paciente !== undefined && params.row.paciente !== null && params.row.paciente !== '-') {
                        if (params.row.presente || (params.row.documento !== '-' && params.row.documento !== null && params.row.documento !== undefined && params.row.presente)) {
                            className += 'row-presente';
                        }
                        if (params.row.atendido) {
                            className += ' row-atendido';
                        }
                    }
                    return className;
                }}
                sx={{
                    '& MuiDataGrid-columnHeaders': {
                        backgroundColor: '#359597'
                    },
                    '& .MuiDataGrid-root': {
                        borderColor: '#359597'
                    },
                    '& .MuiDataGrid-cell[data-field="paciente"]': {
                        justifyContent: 'flex-start'
                    },
                    '& .MuiDataGrid-cell[data-field="obraSocial"]': {
                        justifyContent: 'flex-start'
                    }
                }}
                onRowEditStop={(params, event) => {
                    if (onChange !== undefined) {
                        onChange(params.id, params.field, event.target ? event.target.value : '');
                    }
                }}
                processRowUpdate={(newRow) => {
                    if (onEditCell) onEditCell(newRow);
                    return newRow;
                }}
            />
        </Box>
    );
}
