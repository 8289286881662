import React from 'react';
import { formPopup } from '../components/popup';
import CreateFeriado from './CreateFeriado';
import { saveFeriadoFromSchedule } from '../services/data.service';
import Boton from '../components/boton';

export function CreateNewFeriado () {
    let tempFormData = {};

    const handleAddFeriado = (formData) => {
        tempFormData = {
            titulo: formData.titulo,
            fecha: formData.fecha.split('/').reverse().join('-'),
            prof_cod: formData.profesional
        };
    };

    const openFeriadoPopup = () => {
        formPopup({
            title: 'Nuevo feriado',
            form: <CreateFeriado onSubmit={handleAddFeriado} />
        }).then((response) => {
            if (response.isConfirmed) {
                saveFeriadoFromSchedule(tempFormData);
            }
        });
    };

    return (
        <Boton
            color={'bg-[#314865] hover:bg-[#414865]'}
            ide="saveFeriado"
            texto={'Crear feriado'}
            estilo={'mt-6 h-fit w-fit justify-self-end'}
            onClick={ openFeriadoPopup }/>

    );
}
