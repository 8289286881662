import React, { useEffect, useState } from 'react';
import Boton from '../components/boton';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { formPopup } from '../components/popup';
import { getFeriados } from '../services/data.service';

export default function ShowFeriados () {
    const show = () => {
        formPopup({
            title: 'Feriados',
            form: <ListFeriados/>,
            textConfirm: 'Cerrar'
        });
    };
    return (
        <Boton icono={faCalendar} estilo="mt-6 h-fit w-fit justify-self-end" ide="btnFeriados" color='bg-amber-500 hover:bg-amber-600' onClick={show}/>
    );
}

function ListFeriados () {
    const [feriados, setFeriados] = useState([]);

    useEffect(() => {
        const feriados = async () => {
            const feriados = await getFeriados();
            if (feriados) {
                setFeriados(feriados);
            }
        };
        feriados();
    }, []);

    return (
        <div className='text-left p-4'>
            {feriados.length > 0
                ? (
                    <ul>
                        {
                            feriados.map((feriado) => {
                                const text = feriado.fecha + ' - ' + feriado.titulo + ' - ' + (typeof feriado.prof === 'number' ? 'Todos' : feriado.prof);
                                return <li className='pb-2' key={feriado.id}>{text}</li>;
                            })
                        }
                    </ul>)
                : (
                    <p className="text-gray-500">No existen feriados guardados.</p>
                )}
        </div>
    );
}
